<template>
  <div id="pageRomaneio">
    <v-card>
      <v-container fluid grid-list-lg>
        <v-layout row wrap>
          <v-flex lg12 sm12 xs12>
            <v-card color="blue-grey darken-2" class="white--text">
              <v-card-actions>
                <v-btn flat dark @click="estoque">
                  <span>Estoque</span>
                </v-btn>
              </v-card-actions>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn flat dark @click="saidaPorPracaDataRetorno">
                  <span>Relatório de saídas - Filtro por praça e data prevista de retorno</span>
                </v-btn>
              </v-card-actions>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn flat dark @click="diferencaRomaneioSaidas">
                  <span
                    >Romaneio - Diferença entre quantidade de produtos no romaneio e deixados em notas de saídas</span
                  >
                </v-btn>
              </v-card-actions>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn flat dark @click="itensNaoVendidosPorRomaneio">
                  <span>Romaneio - Conferência</span>
                </v-btn>
              </v-card-actions>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn flat dark @click="vendasPorPeriodo">
                  <span>Vendas - vendas por período e referência</span>
                </v-btn>
              </v-card-actions>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn flat dark @click="vendasPorPeriodoPorLinhaEReferencia">
                  <span>Vendas - vendas por período (todos os itens agrupados por linha e detalhado referência)</span>
                </v-btn>
              </v-card-actions>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn flat dark @click="cobrancaPorPraca">
                  <span>Cobrança - Por Praça</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>
<script>
export default {
  methods: {
    saidaPorPracaDataRetorno() {
      this.$router.push({ name: "saida-por-praca-data-retorno" })
    },
    diferencaRomaneioSaidas() {
      this.$router.push({ name: "diferenca-romaneio-saidas" })
    },
    itensNaoVendidosPorRomaneio() {
      this.$router.push({ name: "itens-nao-vendidos-por-romaneio" })
    },
    vendasPorPeriodo() {
      this.$router.push({ name: "venda-por-periodo-referencia" })
    },
    estoque() {
      this.$router.push({ name: "estoque" })
    },
    vendasPorPeriodoPorLinhaEReferencia() {
      this.$router.push({ name: "venda-por-periodo-por-linha" })
    },
    cobrancaPorPraca() {
      this.$router.push({ name: "cobranca-por-praca" })
    }
  }
}
// color="cyan darken-2"
//  color="purple"
</script>
